<script>
export default {
  name: 'OrderConfirmation',

   mounted() {
    document.title = this.$t("confirmation.title");
  },
};
</script>

<template>
  <div>
    <div class="my-6 mx-auto max-w-xl">
      <div class="my-3 text-left">
        <p class="text-4xl">{{ $t('confirmation.title') }}</p>
      </div>

      <div class="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
        <div class="text-6xl">
          <i class="fas fa-check-circle text-blue-500"></i>
        </div>

        <div class="mt-3 mb-8">
          <p class="text-xl">{{ $t('confirmation.thank_you') }}</p>
        </div>

        <div>
          <span>{{ $t('confirmation.confirmation_text') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
